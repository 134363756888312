<template>
    <div class="cooperate-container">
        <div class="common-first-part">
            <div>
                <p>合作院校</p>
            </div>
            <img :src="require('@/assets/images/index/coop-1.png')" alt="">
        </div>
        <p class="common-title coop-title1">合作院校</p>
        <p class="second-title">本科及以上</p>
        <div class="coop-lists">
            <div class="coop-item">
                <img :src="require('@/assets/images/index/six-11.png')" alt="">
                <span>中科院心理研究所</span>
            </div>
            <div class="coop-item">
                <img :src="require('@/assets/images/index/six-1.png')" alt="">
                <span>首都师范大学</span>
            </div>
            <div class="icons-item">
                <div class="icons-item-img"><img :src="require('@/assets/images/index/wn.png')" alt=""></div>
                <span class="icons-item-name">渭南师范学院</span>
            </div>
            <div class="coop-item">
                <img :src="require('@/assets/images/index/six-7.png')" alt="">
                <span>陕西学前师范学院</span>
            </div>
            <div class="icons-item">
                <div class="icons-item-img"><img :src="require('@/assets/images/index/wl.png')" alt=""></div>
                <span class="icons-item-name">西安文理学院</span>
            </div>
            <div class="coop-item">
                <img :src="require('@/assets/images/index/six-5.png')" alt="">
                <span>山东女子学院</span>
            </div>
            <div class="coop-item">
                <img :src="require('@/assets/images/index/six-10.png')" alt="">
                <span>山东管理学院</span>
            </div>
            <div class="coop-item">
                <img :src="require('@/assets/images/index/six-3.png')" alt="">
                <span>石家庄学院</span>
            </div>
            <div class="coop-item">
                <img :src="require('@/assets/images/index/six-2.png')" alt="">
                <span>衡水学院</span>
            </div>
        </div>
        <p class="second-title">高职高专</p>
        <div class="coop-lists">
            <div class="coop-item">
                <img :src="require('@/assets/images/index/six-14.png')" alt="">
                <span>咸阳职业技术学院</span>
            </div>
            <div class="coop-item">
                <img :src="require('@/assets/images/index/six-9.png')" alt="">
                <span>福建幼儿师范高等专科学校</span>
            </div>
            <div class="icons-item">
                <div class="icons-item-img"><img :src="require('@/assets/images/index/scye.png')" alt=""></div>
                <span class="icons-item-name">四川幼儿师范高等专科学校</span>
            </div>
            <div class="icons-item">
                <div class="icons-item-img"><img :src="require('@/assets/images/index/sjzyr.png')" alt=""></div>
                <span class="icons-item-name">石家庄幼儿师范高等专科学院</span>
            </div>
            <div class="icons-item">
                <div class="icons-item-img"><img :src="require('@/assets/images/index/fy.png')" alt=""></div>
                <span class="icons-item-name">阜阳幼儿师范高等专科学校</span>
            </div>
            <div class="icons-item">
                <div class="icons-item-img"><img class="ll" :src="require('@/assets/images/index/20.png')" alt=""></div>
                <span class="icons-item-name">吕梁师范高等专科学校</span>
            </div>
            <div class="icons-item">
                <div class="icons-item-img"><img :src="require('@/assets/images/index/hb.png')" alt=""></div>
                <span class="icons-item-name">鹤壁职业技术学院</span>
            </div>
        </div>
        <p class="second-title">中职</p>
        <div class="coop-lists">
            <div class="coop-item">
                <img :src="require('@/assets/images/index/six-8.png')" alt="">
                <span>娄底幼儿师范学校</span>
            </div>
        </div>
        <p class="common-title coop-title2">合作幼儿园</p>
        <div class="coop-lists youeryuan">
            <div class="coop-item">
                <img :src="require('@/assets/images/index/six-4.png')" alt="">
                <span>清华大学洁华幼儿园 (总园+分园)</span>
            </div>
            <div class="coop-item">
                <img :src="require('@/assets/images/index/coop-2.png')" alt="">
                <span>湖南梯田教育集团 (70+幼儿园)</span>
            </div>
            <div class="coop-item">
                <img :src="require('@/assets/images/index/coop-3.png')" alt="">
                <span>北京市延庆第四幼儿园</span>
            </div>
            <div class="coop-item">
                <img :src="require('@/assets/images/index/coop-4.png')" alt="">
                <span>北京市通州区教工幼儿园</span>
            </div>
            <div class="coop-item">
                <img :src="require('@/assets/images/index/coop-5.png')" alt="">
                <span>北京市大兴亦庄第二实验幼儿园</span>
            </div>
            <div class="coop-item">
                <img :src="require('@/assets/images/index/coop-6.png')" alt="">
                <span>北京幸福之家幼儿园</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            
        }
    },
    created() {},
    mounted() {},
    methods: {
        
    }
}
</script>
<style lang='scss' scoped>
.cooperate-container {
    .second-title {
        font-size: 16px;
        color: #666666;
        line-height: 22px;
        text-align: center;
        margin: 20px 0 16px;
    }
    .coop-title1 {
        padding-top: 52px;
        // padding-bottom: 42px;
        text-align: center;
    }
    .coop-title2 {
        padding-top: 126px;
        padding-bottom: 42px;
        text-align: center;
    }
    .youeryuan {
        padding-bottom: 137px;
    }
    .coop-lists {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
    }
    .coop-item {
        width: 236px;
        height: 126px;
        position: relative;
        &>img {
            width: 100%;
        }
        &>span {
            position: absolute;
            bottom: 17px;
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
            text-align: center;
            width: 100%;
            display: block;
        }
    }
    .icons-item {
        width: 220px;
        height: 108px;
        padding: 12px;
        margin: 8px 8px;
        box-sizing: border-box;
        background: #FFFFFF;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
        &-img {
            height: 60px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            &>img {
                height: 60px;
            }
            .ll {
                height: 48px;
            }
        }
        &-name {
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
            text-align: center;
            width: 100%;
            display: block;
            margin-top: 10px;
        }
    }
}
</style>